// REDEFINE STARADMIN
$sidebar-gradient-color-1: #18325e;
$sidebar-gradient-color-2: #1a69c6;
$sidebar-light-menu-hover-bg: darken($sidebar-gradient-color-1, 20%);
$sidebar-light-submenu-line-color: darken($sidebar-gradient-color-1, 18%);
$sidebar-light-menu-hover-bg: darken($sidebar-gradient-color-1, 20%);
$sidebar-light-submenu-line-color: darken($sidebar-gradient-color-1, 18%);

$width: 50px;
$sidebar-dark-bg: darken($sidebar-gradient-color-1, 18%);
$sidebar-dark-menu-hover-bg: darken($sidebar-gradient-color-1, 30%);

.navbar.default-layout .navbar-brand-wrapper .navbar-brand img {
  width: 110px !important;
}

.sidebar > .nav .nav-item.nav-profile {
  display: none;
}

.auth {
  img {
    height: 128px;
  }
}