////////// COLOR VARIABLES //////////
$body-text-color: $body-color !default;
$accent-color: #fff !default;
$content-bg:#F3F3F3 !default;
$footer-bg: #dbdbdb !default;
$footer-color: color(dark) !default;
$border-color: #cdd6dc !default;
$footer-height: 60px !default;
$card-bg:#dde4eb !default;
$card-accent-color: #030aac !default;
$card-dropshadow: 0 0 0 0 rgba(90, 113, 208, 0.11),
0 4px 16px 0 rgba(167, 175, 183, 0.33) !default;
$iconholder-primary-bg:#2944b5 !default;
$iconholder-primary-color: #fff !default;
$iconholder-primary-border-color:#4e55c5 !default;
$boxed-layout-bg: lighten($gray-600, 50%);
////////// COLOR VARIABLES //////////

////////// SIDEBAR ////////
$sidebar-width-lg: 270px !default;
$sidebar-width-mini: 185px !default;
$sidebar-width-icon: 70px !default;
$sidebar-light-bg: $white !default;
$sidebar-box-shadow: 0 0 3px 1px #a7a3a3 !default;
$sidebar-gradient-color-1:#5768f3 !default;
$sidebar-gradient-color-2:#1c45ef !default;
$sidebar-gradient:linear-gradient(to top, $sidebar-gradient-color-1, $sidebar-gradient-color-2) !default;
$sidebar-light-menu-color: #fff !default;
$sidebar-light-submenu-color: $sidebar-light-menu-color !default;
$sidebar-light-category-color: rgba(255, 255, 255, 0.7) !default;
$sidebar-light-menu-icon-color: $sidebar-light-menu-color !default;
$sidebar-light-menu-arrow-color: #bfccda !default;
$sidebar-profile-name-color: #fff !default;
$sidebar-profile-designation-color:#fff !default;

$sidebar-light-menu-active-bg: transparent !default;
$sidebar-light-menu-active-color: #fff !default;

$sidebar-light-menu-hover-bg: darken($sidebar-gradient-color-1, 20%);
$sidebar-light-menu-hover-color:#fff !default;
$sidebar-light-submenu-hover-bg: initial !default;
$sidebar-light-submenu-hover-color: #000 !default;
$sidebar-light-submenu-line-color: darken($sidebar-gradient-color-1, 18%);
$sidebar-navlink-menu-item-hover-color:darken($sidebar-light-menu-color, 5%);
$sidebar-nav-item-profile-border-color:1px solid rgba(#fff, 0.11) !default;
$sidebar-nav-item-profile-bg:rgba(255, 255, 255, 0.1) !default;
$sidebar-nav-item-profile-color: #fff !default;
$sidebar-nav-category-border-color:rgba(255, 255, 255, 0.13) !default;
$sidebar-nav-item-dot-color:#fff !default;


$sidebar-dark-bg: #252C46 !default;
$sidebar-dark-menu-color: #9F9EA3 !default;
$sidebar-dark-menu-active-bg: lighten($sidebar-dark-bg, 5%);
$sidebar-dark-menu-active-color: $white !default;
$sidebar-dark-menu-hover-bg: $sidebar-dark-menu-active-bg !default;
$sidebar-dark-menu-hover-color: $sidebar-dark-menu-color !default;
$sidebar-dark-submenu-color: $sidebar-dark-menu-color !default;
$sidebar-dark-submenu-hover-bg: initial !default;
$sidebar-dark-submenu-hover-color: #000 !default;
$sidebar-dark-category-color: #999999 !default;
$sidebar-dark-menu-icon-color: #9F9EA3 !default;
$sidebar-dark-profile-name-color: #404852 !default;
$sidebar-dark-profile-title-color: #8d9498 !default;
$sidebar-menu-font-size: 1.05em !default;
$sidebar-icon-size: 16px !default;
$sidebar-menu-padding: 15px 30px 15px 55px !default;
$sidebar-menu-padding-rtl: 15px 70px 15px 30px !default;
$nav-link-height: 52px !default;
$sidebar-submenu-padding: 0 0 0 4rem !default;
$sidebar-submenu-font-size: $sidebar-menu-font-size !default;
$sidebar-submenu-item-padding: .75rem 1rem !default;
$sidebar-icon-font-size: .9375rem !default;
$sidebar-arrow-font-size: .625rem !default;
$sidebar-profile-bg: transparent !default;
$sidebar-profile-padding: 0rem 1.625rem 2.25rem 1.188rem !default;
$sidebar-mini-menu-padding: .8125rem 1rem .8125rem 1rem !default;
$sidebar-icon-only-menu-padding: .5rem 1.625rem .5rem 1.188rem !default;
$sidebar-icon-only-submenu-width: 200px !default;
$sidebar-icon-only-collapse-shadow: 0 1px 15px 1px rgba(113, 106, 202, .08) !default;
$rtl-sidebar-submenu-padding: 0 3.45rem 0 0 !default;
///////// SIDEBAR ////////

///////// NAVBAR ////////
$navbar-bg: #fff !default;
$navbar-height: 63px !default;
$navbar-light-color: #202339 !default;
$navbar-font-size: $sidebar-menu-font-size !default;
$navbar-icon-font-size: 1.25rem !default;
$navbar-dropdown-toggle-arrow-color:grey !default;
$navbar-count-indicator-bg:#FF0017 !default;
$navbar-dropdown-border-color:rgba(182, 182, 182, 0.1) !default;
$navbar-dropdown-box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.13) !default;
$navbar-menu-wrapper-color:#001737 !default;
$navbar-menu-wrapper-box-shadow:0 4px 16px 0 rgba(167, 175, 183, 0.33) !default;
///////// NAVBAR ////////
$page-header-toolbar-btn-color: theme-color(primary);
$fc-button-bg:theme-color(primary) !default;
$fc-button-color: #fff !default;
$fc-state-active-bg:darken(theme-color(primary), 5%);
$fc-state-active-color:$white !default;
$fc-navigation-button-color: theme-color(primary);
$fc-today-button-bg:theme-color(primary);
$fc-today-button-color: #fff !default;
$fc-event-bg:theme-color(success);
$fc-event-color:$white !default;
$fc-more-cell-color:theme-color(dark);
